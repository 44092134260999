@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --primary-color: #526cfe;
  --dark-color: #530cb3;
  --secondary-color: #00487d;
  --black-color: #000;
  --white-color: #fff;
  --red-color: coral;
  --green-color: forestgreen;
}
* {
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.bg-image:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  bottom: 0;
  right: 0;
  background-color: rgba(238, 240, 255, 0.35);
  z-index: -1;
}

.bg-image {
  margin: 0;
  padding: 0;
  background-size: cover !important;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100% !important;
  height: calc(91vh - 55px);
  z-index: 1;
  position: relative;
  background-color: #f9f9f9;
  background-image: url("../images/login-bg.jpg");
}
img {
  width: 100%;
}
.logo-image img {
  max-width: 140px;
  height: auto !important;
  display: block;
  width: auto;
  cursor: pointer;
}
/* .logo-image {
  width: 200px;
  height: auto !important;
} */
.header {
  background-color: var(--white-color);
  padding: 10px 0 10px;
  box-shadow: 1px 1px 8px #999, 2px 3px 2px #f9f9f9;
  height: fit-content;
  width: 100%;
  background: hsl(231, 0%, 100%) !important;
  display: grid;
  place-items: center;
  position: sticky;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 99;
  /* padding: 0.5rem 0; */
}
.noactive.side-menus .admin-block {
  display: block;
}
.loginbox {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgb(255 255 255 / 76%);
  /* max-height: 450px; */
  padding: 70px 30px 40px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 2px 1px #e9e9e9;
  border: 1px solid #ddd;
}
.avtar-image {
  width: 80px;
  height: 80px;
  background-color: var(--primary-color);
  text-align: center;
  top: -40px;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  left: calc(50% - 36px);
  line-height: 62px;
  /* transform: translate(10px, 10px); */
  padding: 11px;
}
.loginbox h1 {
  margin: 0 !important;
  padding: 0 0 15px;
  font-size: 28px;
  text-transform: uppercase;
}
.loginbox input {
  width: 100%;
  margin-bottom: 20px;
}
.loginbox p {
  margin: 0;
  text-align: left;
  padding: 0;
  font-weight: 700;
}
.loginbox input[type="email"],
.loginbox input[type="password"] {
  border: none;
  background: transparent;
  color: var(--black-color) !important;
  border-bottom: 1px solid #a1a1a1;
  height: 50px;
  outline: none;
  padding: 10px;
  margin: 0 !important;
  padding-left: 40px;
}
.login-button {
  /* background-color: var(--primary-color); */
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  color: #838383;
  margin-right: 30px;
  font-size: 26px;
  cursor: pointer;
}
.jodit-container:not(.jodit_inline) .jodit-wysiwyg img {
  max-width: 100%;
  position: relative;
  width: auto !important;
}
.jodit_fullsize-box_true {
  /* overflow: visible!important; */
  /* position: static!important; */
  z-index: 100000 !important;
  position: initial;
}
.modal-container.jodit_fullsize-box_true {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: inherit;
}
button.log-in {
  width: 100%;
  border: none;
  height: 53px;
  background-color: var(--primary-color);
  color: var(--white-color);
  font-size: 20px;
  border-radius: 20px;
  margin-top: 20px;
}
.input-field {
  position: relative;
  margin: 10px 0 30px;
}
.input-icon {
  position: absolute;
  top: 15px;
  color: var(--primary-color);
  font-size: 20px;
  left: 10px;
}
.footer p {
  font-size: 18px;
  font-weight: bold;
}
/* .footer {
    position: absolute;
    text-align: center;
    background-color: #fff;
    width: 100%;
    padding: 20px 0 10px;
    bottom: 0;
    border-top: 1px solid #ddd;
    box-shadow: 2px 3px 8px 3px #b9b9b9;
} */
.loginbox span {
  text-align: left !important;
  color: var(--red-color) !important;
  font-size: 15px;
}
.error-field {
  text-align: left;
  /* top: -20px !important; */
  padding: 0px 0 10px;
}
.Toastify__toast--error {
  background-color: var(--red-color) !important;
}
.sidebar a.link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
}

.head-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.log-out {
  background-color: var(--primary-color);
  color: var(--white-color);
  width: 200px;
  padding: 11px;
  /* padding: 20px !important; */
  font-size: 20px;
  border: none;
  border-radius: 24px;
}
.sidebar p.link-text,
.link-icon {
  margin: 0 !important;
}
/* Footer css start */
.footer p {
  margin: 0 !important;
}
.footer {
  padding: 10px 0;
  border-top: 1px solid #ddd;
}
/* Footer css end */
/* Dashboard css start */
.side-menu {
  background-image: url("../images/bg-image.png");
  height: 100%;
  /* position: fixed; */
  padding: 0px;
  color: var(--white-color);
  width: 330px;
  left: 0;
  height: 100% !important;
  background-position: center;
  position: relative;
  top: 0;
}
.side-menu:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(15 129 214 / 77%);
  content: "";
}
.admin-block {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--white-color);
  padding: 10px;
  justify-content: space-between;
}
.admin-image {
  width: 70px;
  display: inline-block;
  /* height: 67px; */
}
.sidebar a.link {
  align-items: center;
  color: var(--white-color);
  font-size: 20px;
  text-decoration: none;
  gap: 20px;
  text-align: left;
  padding: 16px 20px;
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
}
.sidebar .menu-item {
  border-bottom: 1px solid #ddd;
  text-align: left;
  width: 100%;
  margin: 0;
}
.sidebar:before {
  position: absolute;
  content: "";
  background-color: rgb(15 129 214 / 77%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.sidebar {
  position: relative;
  padding: 18px 0;
  height: 100%;
}
.head-name h3 {
  font-size: 35px;
}
.head-name p {
  font-size: 28px;
  color: var(--primary-color);
  font-weight: 500;
}
.menu-item {
  padding-bottom: 10px;
}
.das-menu .link-data {
  display: inline-block;
  gap: 30px;
  background-color: var(--white-color);
  color: var(--primary-color);
  font-family: "Open Sans", sans-serif;
  text-align: center;
  min-height: 230px;
  width: 350px;
  padding: 30px 25px 25px;
  border-radius: 20px 20px;
  position: relative;
  z-index: 1;
  text-decoration: none;
  text-align: center;
  min-height: 230px;
}
.link-data:before,
.link-data:after {
  content: "";
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 15px;
  top: 15px;
  bottom: 15px;
  right: 15px;
  z-index: -1;
}
.close-icon svg {
  font-size: 25px;
}
.link-data:after {
  background: transparent;
  width: 100px;
  height: 100px;
  /* border: 8px solid var(--primary-color); */
  border-top: none;
  border-right: none;
  border-radius: 0 0 0 20px;
  box-shadow: none;
  top: auto;
  left: -7px;
  bottom: -10px;
  right: auto;
}

.das-menu .text-box h4 {
  color: #555;
  font-size: 30px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 20px;
  display: block;
  transition: all 0.3s ease 0s;
}

.das-menu .link-icon {
  font-size: 45px;
  line-height: 45px;
  margin: 0 0 20px !important;
  transition: all 0.3s ease 0s;
}
.user-dashboard .das-menu .link-icon img {
  width: 100% !important;
}
/* .das-menu .link-text {
  font-size: 22px;
  text-decoration: none;
  color: var(--black-color);
  font-weight: normal;
} */

.das-menu .link-text {
  font-size: 20px;
  text-decoration: none;
  color: var(--black-color);
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0px;
  text-align: center;
}
a.link-data:hover .item-length {
  text-shadow: 2px 2px 0 #d1d8e0;
}
.text-box h4 {
  color: var(--black-color);
  font-size: 30px;
  font-weight: normal;
}
p.link-text {
  font-size: 18px;
  text-align: left;
  margin: 0;
  text-transform: uppercase;
}
.menus-item .link-icon {
  font-size: 24px;
}

.das-menu .menu-item:first-child,
.das-menu .menu-item:last-child {
  display: none;
}
.sidebar .menu-item.row img {
  display: none;
}
.side-menu h3 {
  display: none;
}
.das-menu {
  padding-top: 40px;
}
/* Dashboard css End */
/* Blog Css start */
thead.thead-dark {
  background-color: var(--primary-color);
  color: var(--white-color);
}
thead.thead-dark th {
  padding: 10px;
  font-size: 18px !important;
}
.blog-table td {
  padding: 12px;
  font-size: 15px;
}
span.status.active {
  color: var(--green-color);
  /* background-color: #219b62; */
  /* font-size: 19px !important; */
}

span.status {
  padding: 12px 0px;
  vertical-align: middle;
  margin-right: 15px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
}
span.status.inactive {
  color: coral;
}
h3 a {
  color: var(--black-color);
  text-decoration: none;
}
.right-content .head-name h3 {
  font-size: 22px;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
}
.head-name {
  padding: 20px 0px 15px;
  text-align: left;
}
.data-content {
  padding: 23px;
  background-color: rgba(227, 227, 227, 0.21);
}
.data-content .top-button {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  align-items: center;
}
button.new-blog {
  border: 1px solid var(--primary-color);
  font-size: 23px;
  background-color: var(--white-color);
  background: var(--white-color);
  color: var(--primary-color);
  padding: 0.2em 0.7rem !important;
  /* border-radius: 10px; */
}
.searchText {
  border: 1px solid #ddd;
  padding: 0rem !important;
  width: 0rem;
  border-radius: 5rem;
  border: none;
  outline: none;
  font-size: 18px;
  transition: all 0.3s linear;
}
.searchBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBtn {
  width: 3rem;
  height: 3rem;
  background-color: var(--primary-color);
  position: absolute;
  color: var(--white-color);
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 18px;
  right: 1rem;
}
.searchBox.blockSearch .searchBtn {
  left: 1rem;
}
.searchBox.blockSearch:hover > .searchText {
  padding: 1rem 4.9rem !important;
}

.searchBox:hover > .searchText {
  width: 20rem !important;
  padding: 1rem 3.9rem 1rem 1rem !important;
}
.searchBox:hover > .searchBtn {
  background-color: var(--white-color);
  color: var(--primary-color);
}
button.new-blog:hover {
  background-color: var(--primary-color);
  box-shadow: 2px 3px 4px rgb(0 0 0 / 33%);
  color: var(--white-color) !important;
  border-color: var(--primary-color);
}
.blog-table svg {
  cursor: pointer;
}

/* Blog Css end */

/* Pagination Css start*/
.pagination {
  padding: 18px;
}
li.page-item {
  display: inline-block;
  padding: 8px 15px;
}
li.page-item.active span.page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
li.page-item span.page-link {
  padding: 6px 25px;
  font-size: 18px;
  cursor: pointer;
}
ul.pagi-number {
  margin: 0 !important;
  padding: 0 !important;
}
.pagination button {
  padding: 0px 25px;
  border: none;
}
/* Pagination Css end*/
/* Add Blog Css start*/
.card {
  text-align: initial;
}
button.generate-url {
  max-width: 250px;
  padding: 9px;
  border: 1px solid #ddd;
  display: block;
  background-color: var(--primary-color);
  color: var(--white-color);
  margin-top: 20px;
  box-shadow: 2px 3px 4px #979797;
}
.bottom-button {
  text-align: center !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
  margin: 0 10px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

label.switch.true .slider {
  background-color: var(--primary-color);
}

label.switch.true .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

label.switch.true .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
td svg {
  color: #484848;
}
span.non-valid {
  color: var(--red-color);
}
.Toastify__toast--success {
  background-color: #198150 !important;
  box-shadow: none;
}
.Toastify__toast--success svg {
  fill: var(--white-color) !important;
}
/* Add Blog Css end*/
/* Popup-class css start*/
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
}
section.add-post .card-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  background-color: var(--white-color) !important;
  min-width: 40rem;
  padding: 2rem 2rem;
  border-radius: 0.5rem;
  width: 55rem;
  z-index: 99;
}
.modal-body input[type="text"] {
  padding: 10px !important;
}
.modal-content {
  width: 55rem !important;
}
.modal-dialog {
  max-width: max-content !important;
}
input#documentuplad {
  position: absolute;
  top: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1px;
  cursor: pointer;
  opacity: 0;
  left: 0;
}
.file-field {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.btn.helensys-theme-btn span {
  background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%) !important;
  color: var(--white-color) !important;
  font-size: 0.8rem;
  padding: 0.5rem 1.6rem;
}
.file-path-wrapper {
  overflow: hidden;
  padding-left: 10px;
  display: inline-block;
}
.file-path-wrapper input[type="text"] {
  border: none;
  width: 100% !important;
  font-size: 15px;
  padding: 10px 0 !important;
  border-bottom: 1px solid #ddd;
}
.css-1p3m7a8-multiValue {
  display: none;
}
td h4,
td p,
td h1 {
  font-size: 17px !important;
  font-weight: 400;
}
tbody tr td ul li {
  list-style: none;
}
/* .blog-table tbody tr td:last-child {
  display: none;
} */
/* Popup-class css end*/
/* View Blog css start */
.meta-tag b {
  padding: 10px;
  font-size: 18px;
  font-weight: normal;
}
.meta-tag p {
  margin: 0 !important;
  padding: 10px !important;
  display: inline-block;
}
section.view-blog .card-body {
  padding: 30px 50px;
}
.card-text h3 {
  font-weight: normal;
}
.meta-tag {
  color: var(--primary-color);
  border-top: 1px solid var(--black-color);
  border-bottom: 1px solid var(--black-color);
  border: solid #dddd;
  border-width: 1px 0px 1px 0px;
}
/* View Blog css end */

.top-button.block-mail {
  text-align: left;
  margin-bottom: 10px;
}
/* Modal CSS start */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 99 !important;
}
.modal-footer button {
  padding: 8px 25px;
  font-size: 18px;
}
/* CSS for the modal container */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white !important;
  padding: 20px;
  border-radius: 5px;
  max-width: 65rem;
  width: 90%;
  text-align: center;
  padding: 0 !important;
  z-index: 99;
}
/* CSS for the modal header */
.modal-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* CSS for the modal content */
.modal-container p {
  font-size: 16px;
  line-height: 1.5;
}
.category_list {
  display: flex;
  justify-content: space-around;
}

/* CSS for the close button */
.modal-container .close {
  background-color: #007bff; /* You can customize the button color here */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

/* Optional: Add hover effect for the close button */
.modal-container .close:hover {
  background-color: #0056b3; /* You can customize the hover color here */
}
.modal-open {
  overflow: hidden; /* Disable scroll on the body only when modal is open */
}
.modal-footer {
  padding-top: 20px;
}
section.view-blog {
  display: block;
  text-align: initial !important;
}
label.heading {
  margin-bottom: 0.3rem !important;
  font-family: Roboto-Bold;
  color: #817f7f;
  font-size: 16px;
  font-weight: 700;
  display: block;
}
.view-blog h4 {
  font-weight: 400 !important;
  font-size: 18px;
}
.view-blog p {
  font-weight: 400;
}
.view-sec img {
  display: block;
  width: auto !important;
  cursor: pointer;
  max-width: 100% !important;
  margin-bottom: 10px;
}
.view-blog ul {
  list-style: none;
  padding: 0;
}
.edit-form textarea {
  min-height: 100px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
}
.edit-form label {
  display: block;
  font-size: 18px;
}
.select-list {
  width: 100%;
  border: 1px solid #ddd;
  padding: 12px;
}
.edit-form .toggle {
  display: flex;
}
table .modal-wrapper {
  background-color: rgba(0, 0, 0, 0.17) !important;
}
.email {
  display: inline-block;
  align-items: center;
}
.email label {
  margin: 0 !important;
  display: inline-block;
}
input#email-confi {
  margin: 0 10px;
  vertical-align: text-top;
  border: 2px solid #7d7d7d;
}
.modal-head {
  padding: 5px 20px 5px 30px;
  margin: 0;
  background-color: var(--primary-color);
  color: var(--white-color);
  text-align: left;
  border-bottom: 1px solid #ddd;
  box-shadow: 2px 1px 3px #cbcbcb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}
.close-icon {
  font-size: 24px;
  cursor: pointer;
}
.modal-head h1 {
  margin: 0 !important;
}
.modal-body {
  margin: 0 !important;
  padding: 12px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}
.logo-image {
  display: flex;
  /* align-items: end; */
}
.icon-bar {
  font-size: 25px;
}
.icon-bar {
  font-size: 25px;
  cursor: pointer;
}
/* Modal CSS end */

/*side-bar css start*/
main {
  width: 100%;
}
.menu-bar {
  display: flex;
}
.side-menus {
  background-color: var(--primary-color);
  transition: all 0.5s;
  min-height: 100vh;
}
.menus-item a {
  display: flex;
  color: var(--white-color);
  text-decoration: none;
  align-items: center;
  padding: 8px;
}
.link-icon img {
  width: 75px;
  padding: 6px;
}

.bar {
  padding: 15px;
  font-size: 25px;
  color: var(--white-color);
  cursor: pointer;
}
.admin-block h2 {
  color: var(--white-color);
}
.text-box {
  padding: 0 14px;
}
.menus-item a:hover {
  background-color: var(--dark-color);
  transition: all 0.3s;
}
.link.active {
  background-color: var(--dark-color);
}
.modal-head h1 {
  text-transform: uppercase !important;
  font-size: 18px;
}
/*side-bar css end*/
/* error page css start */
.error-page {
  display: block;

  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  padding: 40px 0;
}

.error-image {
  max-width: 600px;
  margin-bottom: 30px;
}

.error-heading {
  font-size: 6rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.error-text {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.go-home-link {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.go-home-link:hover {
  background-color: var(--primary-color);
}
td.category-name tr {
  border: 1px solid #ddd;
  margin-bottom: 10px !important;
  display: block;
}
td.category-name tr {
  border: 1px solid #ddd;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
}
.status-details {
  margin: 12px 0;
}
/* error page css end*/
/* Master page css start */
.board-desc p {
  margin: 0;
}
.board-desc img {
  width: auto;
}
.board-desc {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.notice-board.data-content h3 {
  background-color: var(--primary-color);
  padding: 8px 20px;
  color: var(--white-color);
  font-size: 22px;
}
ul.submenus a {
  color: var(--white-color);
  text-decoration: none;
  text-align: left;
  font-size: 18px;
}
ul.submenus {
  background-color: var(--primary-color);
  padding: 0;
  height: 100vh;
  margin: 0;
}
ul.submenus li {
  list-style: none;
  border-bottom: 1px solid #dddd;
  padding: 9px 20px;
}
/* Master page css end */

/*Faqs css start */
.add-data.row {
  display: flex;
  align-items: center;
}
.add-btn {
  text-align: end;
}
.add-data.row .my-3 {
  margin-top: 0px !important;
}
/*Faqs css end */
/* Delete Button Css start */
.delete-content .close-icon {
  text-align: end;
  color: #c3c3c3;
  padding: 5px 15px 0px !important;
}
.delete-content {
  max-width: 20% !important;
  width: auto !important;
  background-color: #fffafa !important;
}
.delete-content .modal-body {
  padding: 10px 20px 30px;
}
.delete-buttons .btn {
  padding: 10px 34px;
  margin: 10px !important;
}
.del-icon {
  color: var(--primary-color);
  font-size: 35px;
  padding: 0 0 10px !important;
}
.delete-box p {
  font-size: 18px;
  line-height: 32px;
}
.delete-box h2 {
  color: #565656;
}
button.confirm-button.btn.btn-danger {
  background-color: var(--red-color);
}
.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter svg {
  fill: var(--white-color) !important;
}

/* Delete Button Css end */

/* Preloader css */

.preloader-div-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
}
.preloader-div {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40%;
  margin-top: -50px;
}
.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
button.cancel-button.btn.btn-secondary {
  background-color: var(--primary-color) !important;
}
section.view-sec tbody tr td {
  border: 1px solid #ddd;
}
.Toastify__toast-container {
  z-index: 99999 !important;
}
.Toastify__toast--warning {
  background-color: #ffc107;
}

/* .jodit-ui-search__buttons button {
  width: 24% !important;
}
.jodit-ui-search__buttons button.jodit-ui-button {
  width: 100% !important;
} */